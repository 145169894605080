<template>
  <g :id="`Male55Avatar_${uniqueIdSuffixA}`">
    <g>
      <circle style="fill: #d0d0d0" cx="24.2" cy="47.2" r="23.6" />
    </g>
    <g>
      <g>
        <path
          :style="{ fill: ringColorHex }"
          :stroke="ringColorHex ? 'rgba(255,255,255,0.5)' : ''"
          d="M24.2,71.1c-13.2,0-23.9-10.7-23.9-23.9s10.7-23.9,23.9-23.9c13.2,0,23.9,10.7,23.9,23.9
				S37.4,71.1,24.2,71.1z M24.2,27.2c-11,0-20,9-20,20s9,20,20,20s20-9,20-20S35.2,27.2,24.2,27.2z"
        />
      </g>
    </g>
    <g>
      <defs>
        <circle
          :id="`SVGID_123456_${uniqueIdSuffixA}`"
          cx="24.2"
          cy="47.2"
          r="19.1"
        />
      </defs>
      <clipPath :id="`SVGID_7890_${uniqueIdSuffixB}`">
        <use
          :xlink:href="`#SVGID_123456_${uniqueIdSuffixA}`"
          style="overflow: visible"
        />
      </clipPath>
      <g :style="`clip-path: url(#SVGID_7890_${uniqueIdSuffixB})`">
        <g>
          <g>
            <path
              style="fill: #333333"
              d="M36.3,100.8l0,5.5H12.9l0-5.5h-0.2h-0.1l-2-0.6l-0.9-0.3l-2.2-0.7v0l0-0.4l0.4-36
						c0.5-1.5,1.6-2.6,3.1-3l2.7-0.7l3.8-1l-0.1,0.1l4.5,15.3l-1.7,13.7l4.2,6.6l4.1-6.6L27,73.5l4.5-15.2l0.1-0.2L31.6,58l3.8,1
						l2.7,0.7c1.5,0.4,2.7,1.5,3.1,3l0.4,36l0,0.4v0l-2.2,0.7l-0.9,0.3l-2,0.6h-0.1H36.3z"
            />
            <path
              style="fill: #edc593"
              d="M31,39.5c0.5,2.2,0.4,4.5,0.4,4.5l0.2,0.6l0.2-2.3l0.1,0.2h0.8c0.7,0,0.8,0.6,0.8,1.3v1.4
						c0,0.7-0.3,1-0.8,1.3c-0.2,0.1-0.9,0.5-0.9,0.5h-0.1v0.5c0,2-0.9,3.7-2.3,4.8v0c0,0,0,0,0,0c-0.7,0.6-1.6,1-2.6,1.2h-4.2
						c-1.1-0.2-2-0.6-2.8-1.2v0c-1.5-1.1-2.4-2.8-2.4-4.8v-0.5l0.3-2.3l0.2-0.6c0,0-0.1-2.3,0.4-4.5c0.4-2.1,1.4-4.3,3.3-4.3
						c0.9,0,1.8,0.3,3.2,0.3c1.3,0,1.8-0.4,2.9-0.3C29.6,35.4,30.6,37.4,31,39.5z M25.3,45.5c0.2-0.2,0.5-0.6,0.5-1.4
						c0-0.3-0.2-0.4-0.5-0.4c-0.3,0-0.5,0.2-0.4,0.5c0,0.3,0,0.5-0.2,0.7C24.5,45,24.1,45,24,45c-0.3,0-0.5,0.2-0.5,0.4
						c0,0.3,0.2,0.5,0.4,0.5c0,0,0.1,0,0.2,0C24.3,45.9,24.9,45.8,25.3,45.5z"
            />
            <path
              style="fill: #8e6f50"
              d="M31.9,42.2l0,0.2l-0.2,2.3L31.4,44c0,0,0.1-2.3-0.4-4.5l0.7-0.5c0,0.2,0,0.3,0,0.5L31.9,42.2z"
            />
            <path
              style="fill: #8e6f50"
              d="M31.7,39L31,39.5c-0.5-2.1-1.4-4.1-3.3-4.2c-1.1-0.1-1.6,0.3-2.9,0.3c-1.3,0-2.2-0.3-3.2-0.3
						c-2,0-2.9,2.1-3.3,4.3L17.4,39c0.3-3.4,3.1-6.1,6.6-6.1h1.1C28.6,32.8,31.5,35.5,31.7,39z"
            />
            <path
              style="fill: #f5f5f5"
              d="M31.5,58.3l-3.1,4.8l-1.8-1.7l-1.8-1.6l4.7-4.4v0.7c0,0.9,0.9,1.6,1.7,1.9l0.4,0.1l0.1,0.1
						L31.5,58.3z"
            />
            <path
              style="fill: #f5f5f5"
              d="M29.7,55.2l1.9,2.8l-0.4-0.1c-0.9-0.2-1.7-1-1.7-1.9v-0.7L29.7,55.2z"
            />
            <polygon
              style="fill: #ececec"
              points="31.5,58.3 27,73.5 25.8,63.3 25.9,63.3 26.5,61.4 28.4,63.1 					"
            />
            <path
              style="fill: #d3ae84"
              d="M29.5,52.3v3.1l-4.7,4.4h-0.3l-4.7-4.4v-3.1l0.1-0.1c0.8,0.6,1.8,1,2.8,1.2h4.2
						C27.8,53.2,28.7,52.8,29.5,52.3L29.5,52.3L29.5,52.3z"
            />
            <polygon
              style="fill: #333333"
              points="27,73.5 28.7,87.1 24.6,93.7 20.4,87.1 22.1,73.4 24.6,81.8 					"
            />
            <polygon
              style="fill: #4080c1"
              points="25.8,63.3 27,73.5 24.6,81.8 22.1,73.4 23.3,63.3 					"
            />
            <polygon
              style="fill: #3b79af"
              points="24.7,59.8 26.5,61.4 25.9,63.3 25.8,63.3 23.3,63.3 22.7,61.4 24.4,59.8 					"
            />
            <path
              style="fill: #d3ae84"
              d="M25.8,44.1c0,0.7-0.2,1.2-0.5,1.4c-0.4,0.4-1,0.4-1.2,0.4c-0.1,0-0.2,0-0.2,0
						c-0.3,0-0.4-0.3-0.4-0.5c0-0.3,0.3-0.4,0.5-0.4c0.1,0,0.5,0,0.7-0.2c0.1-0.1,0.2-0.4,0.2-0.7c0-0.3,0.2-0.5,0.4-0.5
						C25.6,43.6,25.8,43.8,25.8,44.1z"
            />
            <path
              style="fill: #f5f5f5"
              d="M22.7,61.4l-1.9,1.7l-3.2-5l0.1-0.1l0.4-0.1c0.8-0.2,1.7-1,1.7-1.9v-0.7l4.7,4.4L22.7,61.4z"
            />
            <polygon
              style="fill: #ececec"
              points="22.7,61.4 23.3,63.3 22.1,73.4 17.5,58.1 20.8,63.1 					"
            />
            <path
              style="fill: #f5f5f5"
              d="M19.7,55.4v0.7c0,0.9-0.9,1.6-1.7,1.9L17.6,58l1.9-2.8L19.7,55.4z"
            />
            <path
              style="fill: #8e6f50"
              d="M17.4,39l0.9,0.6c-0.5,2.2-0.4,4.5-0.4,4.5l-0.2,0.6l-0.4-2.1l0-0.3l0-2.8
						C17.4,39.3,17.4,39.1,17.4,39z"
            />
            <path
              style="fill: #edc593"
              d="M17.3,42.5l0.4,2.1l-0.3,2.3h0c0,0-0.7-0.3-0.9-0.5c-0.5-0.3-0.8-0.6-0.8-1.3v-1.4
						c0-0.7,0.1-1.3,0.8-1.3H17.3z"
            />
          </g>
        </g>
        <path
          style="fill: #8e6f50"
          d="M24.7,51.8c0.2,0,0.3,0.2,0.3,0.4c0,0.4,0.1,1.1,0.5,1.1c0.5,0,1.7-2.7,1.7-3.1s-2-1.8-2.3-1.9
				c-0.1,0-0.1-0.1,0-0.2c0.1-0.4,0.5-0.5,0.8-0.4c0.9,0.4,2.5,1.6,2.6,2c0.3,1.2-1.1,3.9-1.5,4.5s-2.3,0.5-2.3,0.5
				s-1.9,0.1-2.3-0.5s-1.8-3.2-1.5-4.5c0.1-0.4,1.7-1.5,2.6-2c0.3-0.2,0.7,0,0.8,0.4c0,0.1,0,0.2,0,0.2c-0.3,0.1-2.3,1.5-2.3,1.9
				s1.2,3.1,1.7,3.1c0.4,0,0.4-0.7,0.5-1.1c0-0.2,0.2-0.4,0.3-0.4H24.7z"
        />
      </g>
    </g>
  </g>
</template>
<script>
export default {
  props: {
    ringColor: {
      type: String,
      default: "#b5b4b5",
    },
  },
  computed: {
    ringColorHex() {
      return this.ringColor;
    },
    uniqueIdSuffixA() {
      return Math.floor(Math.random() * (25 - 2) + 2);
    },
    uniqueIdSuffixB() {
      return Math.floor(Math.random() * (50 - 26) + 62);
    },
  },
};
</script>
